
@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/fa-brands-400.eot?v=5.0.9");
    src: url("../fonts/fa-brands-400.eot?#iefix&v=5.0.9") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2?v=5.0.9") format("woff2"), url("../fonts/fa-brands-400.woff?v=5.0.9") format("woff"), url("../fonts/fa-brands-400.ttf?v=5.0.9") format("truetype"), url("../fonts/fa-brands-400.svg?v=5.0.9#fontawesome") format("svg");
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/fa-regular-400.eot?v=5.0.9");
    src: url("../fonts/fa-regular-400.eot?#iefix&v=5.0.9") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2?v=5.0.9") format("woff2"), url("../fonts/fa-regular-400.woff?v=5.0.9") format("woff"), url("../fonts/fa-regular-400.ttf?v=5.0.9") format("truetype"), url("../fonts/fa-regular-400.svg?v=5.0.9#fontawesome") format("svg");
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/fa-solid-900.eot?v=5.0.9");
    src: url("../fonts/fa-solid-900.eot?#iefix&v=5.0.9") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2?v=5.0.9") format("woff2"), url("../fonts/fa-solid-900.woff?v=5.0.9") format("woff"), url("../fonts/fa-solid-900.ttf?v=5.0.9") format("truetype"), url("../fonts/fa-solid-900.svg?v=5.0.9#fontawesome") format("svg");
}
